import { useRouter } from 'next/router';

export let useQuery = (id: string) => {
  let router = useRouter();

  let value = Array.isArray(router.query[id])
    ? router.query[id][0]
    : (router.query[id] as string);

  if (!value && typeof window != 'undefined') {
    value = new URLSearchParams(window.location.search).get(id);
  }

  return value;
};
