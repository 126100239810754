export let Logo = ({ size, color }: { size?: number | string; color?: string }) => {
  return (
    <svg
      width={size || 30}
      height={size || 30}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Highloop Logo"
    >
      <rect width="23.3333" height="23.3333" rx="4" fill={color || 'var(--vapor-primary)'} />
      <circle cx="44.3334" cy="11.6667" r="11.6667" fill={color || 'var(--vapor-primary)'} />
      <rect
        x="32.6667"
        y="32.6666"
        width="23.3333"
        height="23.3333"
        rx="4"
        fill={color || 'var(--vapor-primary)'}
      />
      <circle cx="11.6667" cy="44.3333" r="11.6667" fill={color || 'var(--vapor-primary)'} />
    </svg>
  );
};
