import React from 'react';
import { styled } from 'buttered';
import Head from 'next/head';
import { ChevronLeft } from 'react-feather';
import { Button, Spacer } from '@vapor/ui';
import { useQuery } from '../hooks/useQuery';
import { Logo } from '../components/logo';

let Wrapper = styled('div')``;

let Inner = styled('div')``;

let Image = styled('div')`
  width: 100vw;
  height: calc(100vh - 120px);
  margin-top: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

let Nav = styled('nav')`
  padding: 15px 20px;
  background: white;
  border-bottom: var(--vapor-border);
  display: flex;

  .logo {
    display: flex;
    margin: 0px auto;
    width: fit-content;

    p {
      margin-left: 12px;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
    }
  }
`;

export default function Home() {
  let image = useQuery('image');

  return (
    <Wrapper>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            body {
              background: #222;
            }
            `
          }}
        />
      </Head>

      <Inner>
        <Nav>
          <Button size="small" icon={<ChevronLeft />} onClick={() => window.close()}>
            Back
          </Button>

          <div className="logo">
            <Logo />

            <p>Highloop</p>
          </div>

          <Spacer width="50px" />
        </Nav>

        <Image>{image && <img src={image} alt="Image Preview" />}</Image>
      </Inner>
    </Wrapper>
  );
}
